import  SbciProductMasterApiUrls  from '../../../../apiUrls/SbciProductMasterApiUrls'
import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import { components} from 'cng-web-lib'
import PropTypes from 'prop-types'
import React from 'react'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngLookupAutocompleteField
    },
  },
  CngGridItem,
} = components

/**
 *
 * @visibleName Code maintenance autocomplete
 */
function SbciProductMasterAutoCompleteMasterField(props) {

  const {
    lookupProps = {},
    ...lookupAutocompleteProps
  } = props
  const { filters = [], ...otherLookupProps } = lookupProps

  return (
    <CngLookupAutocompleteField
      {...lookupAutocompleteProps}
      lookupProps={{
        url: SbciProductMasterApiUrls.GET,
        label: 'productId',
        value: 'productId',
        filters: [
          ...filters
        ],
        ...otherLookupProps
      }}
    />
  )
}

export default SbciProductMasterAutoCompleteMasterField
