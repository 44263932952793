import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { constants, useTranslation, useServices } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import SbciManifestKeys from '../../../constants/locale/key/SbciManifest'
import SbciInvoiceApiUrls from '../../../apiUrls/SbciInvoiceApiUrls'
import SbciManifestApiUrls from '../../../apiUrls/SbciManifestApiUrls'
import pathMap from '../../../paths/pathMap'
import InvoiceLookupsProvider from '../lookups/InvoiceLookupsContext'
import Table from '../../../components/aciacehighway/Table'
import InvoiceDialog from './InvoiceDialog'
import AttachInvoiceDialog from './AttachInvoiceDialog'
import { getStatusMetadata } from '../../../common/NACommon'
import { FileForUserGetUserDetails } from '../../../common/FileForUserCommon'
import moment from 'moment'
import { Chip, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  filter: { EQUAL }
} = constants

function InvoiceAttachTable(props) {
  const { manifestId, manifestStatus, showNotification } = props

  const tableRef = useRef()
  const [invoiceDialog, setInvoiceDialog] = useState({
    open: false,
    invoice: null
  })
  const [attachInvoiceDialog, setAttachInvoiceDialog] = useState(false)
  const history = useHistory()
  const theme = useTheme()
  const { createRecord, securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const manifest = translate(Namespace.SBCI_MANIFEST, SbciManifestKeys.TITLE)
    const invoice = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TITLE_IN_MANIFEST
    )
    const invoiceId = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.ID_IN_MANIFEST
    )
    const invoiceNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.INVOICE_NO
    )
    const invoiceDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.INVOICE_DATE
    )
    const entryNo = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.ENTRY_NO)
    const invStatus = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.STATUS)
    const totalValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TOTAL_VALUE
    )
    const grossWeight = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.GROSS_WEIGHT
    )
    const netWeight = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NET_WEIGHT
    )
    const attachButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.ATTACH_BUTTON
    )
    const editSentRecords = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.EDIT_SENT_RECORDS
    )
    const deleteSentRecords = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.DELETE_SENT_RECORDS
    )
    const deleteButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.DELETE_BUTTON
    )
    const deleteAllButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.DELETE_ALL_BUTTON
    )
    const cloneButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CLONE_BUTTON
    )
    const detachSentRecord = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.DETACH_SENT_RECORD
    )
    const submitSentRecord = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.SUBMIT_SENT_RECORD
    )
    const submitButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.SUBMIT_BUTTON
    )
    const detachMessage = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.DETACH_MESSAGE
    )
    const detachSuccessMsg = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.DETACH_SUCCES_MSG
    )
    const sendToUPS = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.SEND_TO_UPS
    )
    const sendManifestWithInvoices = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.SEND_MANIFEST_WITH_INVOICES
    )
    const submitSuccessMsg = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.SUBMIT_SUCCESS_MSG
    )
    const errorMessage = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.ERROR_MESSAGE
    )
    const editButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.EDIT_BUTTON
    )
    const cancelButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CANCEL_BUTTON
    )
    const detachButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.DETACH_BUTTON
    )

    return {
      manifest,
      invoiceId,
      invoiceNo,
      invoiceDate,
      entryNo,
      invStatus,
      invoice,
      grossWeight,
      netWeight,
      totalValue,
      attachButton,
      editSentRecords,
      deleteSentRecords,
      deleteButton,
      deleteAllButton,
      cloneButton,
      detachSentRecord,
      submitSentRecord,
      submitButton,
      detachMessage,
      detachSuccessMsg,
      sendToUPS,
      sendManifestWithInvoices,
      submitSuccessMsg,
      errorMessage,
      editButton,
      cancelButton,
      detachButton
    }
  }

  const columns = [
    {
      field: 'id',
      title: translatedTextsObject.invoiceId
    },
    {
      field: 'invoiceNo',
      title: translatedTextsObject.invoiceNo
    },
    {
      field: 'invoiceDate',
      title: translatedTextsObject.invoiceDate,
      render: (rowData) =>
        rowData.invoiceDate &&
        moment(rowData.invoiceDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'entryNo',
      sortKey: 'entryNo',
      title: translatedTextsObject.entryNo
    },
    {
      field: 'grossWt',
      sortKey: 'grossWt',
      title: translatedTextsObject.grossWeight
    },
    {
      field: 'netWt',
      sortKey: 'netWt',
      title: translatedTextsObject.netWeight
    },
    {
      field: 'totalValue',
      sortKey: 'totalValue',
      title: translatedTextsObject.totalValue
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.invStatus,
      render: (rowData) => {
        const status = getStatusMetadata(rowData.status)

        return (
          <Chip
            label={status.label}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor,
              fontSize: 12
            }}
          />
        )
      }
    }
  ]

  function handleCloneInvoice(rowData) {

    const data = {id:rowData.id}
    createRecord.execute(
      SbciInvoiceApiUrls.CLONE,
      data,
      (res) => (
        res.manifestId = manifestId,
        history.push({
          pathname: pathMap.SBCI_INVOICE_ADD_VIEW,
          state: { cloneData: res, manifestId: manifestId }
        })),
      (error) => {
        console.error(error)
        showNotification('error', 'Something went wrong when cloning invoice.')
      }
    )
  }

  function handleDetachInvoices(invoices) {
    try {
      if (tableRef.current.setLoading) {
        tableRef.current.setLoading(true)
      }

      securedSendRequest.execute(
        'POST',
        SbciManifestApiUrls.MANIFEST_BASE_DETACH,
        {
          manifestId: parseInt(manifestId),
          invoiceId: invoices.map((invoice) => invoice.id)
        },
        (response) => {
          const { errorMessages } = response

          if (errorMessages) {
            errorMessages.forEach((message) =>
              showNotification('error', message)
            )
          } else {
            showNotification('success', translatedTextsObject.detachSuccessMsg)

            if (tableRef.current.performRefresh) {
              tableRef.current.performRefresh()
            }
          }
        },
        (error) => {
          console.error(error)
          showNotification('error', 'Invoice detach failed.')
        },
        () => {
          if (tableRef.current.setLoading) {
            tableRef.current.setLoading(false)
          }
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  function handleSubmitInvoices(invoices) {
    try {
      const result = [...invoices]

      if (tableRef.current.setLoading) {
        tableRef.current.setLoading(true)
      }

      const fileForUserDetails = FileForUserGetUserDetails()

      if (fileForUserDetails) {
        const { fileForUserId, fileForUserLoginId, fileForUserPartyId } =
          fileForUserDetails

        result.forEach((invoice) => {
          invoice['fileForUserId'] = fileForUserId
          invoice['fileForUserLoginId'] = fileForUserLoginId
          invoice['fileForUserPartyId'] = fileForUserPartyId
        })
      }

      securedSendRequest.execute(
        'POST',
        SbciManifestApiUrls.SUBMIT,
        result,
        (response) => {
          const { errorMessages } = response

          if (errorMessages) {
            errorMessages.forEach((message) =>
              showNotification('error', message)
            )
          } else {
            showNotification('success', translatedTextsObject.submitSuccessMsg)

            if (tableRef.current.performRefresh) {
              tableRef.current.performRefresh()
            }
          }
        },
        (error) => {
          console.error(error)
          showNotification('error', 'Invoice submit failed.')
        },
        () => {
          if (tableRef.current.setLoading) {
            tableRef.current.setLoading(false)
          }
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'secondary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () =>
                history.push(
                  {
                    pathname: pathMap.SBCI_INVOICE_ADD_VIEW, 
                    state: {manifestId: parseInt(manifestId)}
                })
            },
            label: 'Create new invoice'
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'link']} />,
              onClick: () => setAttachInvoiceDialog(true)
            },
            label: 'Attach invoice'
          }
        ]}
        checkboxSelection
        columns={columns}
        compact
        customRowActions={[
          {
            iconButtonProps: {
              icon: ['fal', 'arrow-alt-right'],
              style: { color: theme.palette.primary.main }
            },
            onClick: (rowData) => handleSubmitInvoices([rowData]),
            tooltip: () => translatedTextsObject.sendToUPS
          }
        ]}
        fetch={{ url: SbciInvoiceApiUrls.GET }}
        fetchFilters={[
          { field: 'manifestId', operator: EQUAL, value: manifestId }
        ]}
        onRowClick={(rowData) =>
          setInvoiceDialog({ open: true, invoice: rowData })
        }
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) =>
              history.push({
                pathname: `${pathMap.SBCI_INVOICE}/edit/${rowData.id}`,
                state: { manifestId: manifestId }
              })
              //history.push(`${pathMap.SBCI_INVOICE}/edit/${rowData.id}`)
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneInvoice
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'link-slash']} />,
            label: translatedTextsObject.detachButton,
            onClick: (rowData) => handleDetachInvoices([rowData])
          }
        ]}
        selectActions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              variant: 'outlined',
              style: {
                minWidth: 'auto'
              }
            },
            disabled: (rows) => rows.some((rowData) => rowData.status === 'ST'),
            icon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
            label: translatedTextsObject.sendToUPS,
            onClick: (rows) => handleSubmitInvoices(rows),
            tooltip: (rows) =>
              rows.some((rowData) => rowData.status === 'ST')
                ? translatedTextsObject.submitSentRecord
                : null
          },
          {
            buttonProps: {
              size: 'medium',
              color: 'secondary',
              style: { color: theme.palette.error.main, minWidth: 'auto' }
            },
            disabled: (rows) => rows.some((rowData) => rowData.status === 'ST'),
            icon: <FontAwesomeIcon icon={['fal', 'link-slash']} />,
            label: translatedTextsObject.detachButton,
            onClick: (rows) => handleDetachInvoices(rows),
            tooltip: (rows) =>
              rows.some((rowData) => rowData.status === 'ST')
                ? translatedTextsObject.detachSentRecord
                : null
          }
        ]}
        showNotification={showNotification}
        tableRef={tableRef}
        variant='outlined'
      />
      <InvoiceLookupsProvider>
        <AttachInvoiceDialog
          open={attachInvoiceDialog}
          onClose={() => setAttachInvoiceDialog(false)}
          showNotification={showNotification}
          manifestId={manifestId}
          manifestStatus={manifestStatus}
          onInvoiceClick={(data) =>
            setInvoiceDialog({ open: true, invoice: data })
          }
          onAttachInvoice={() => {
            if (tableRef.current.performRefresh) {
              tableRef.current.performRefresh()
            }
          }}
        />
        <InvoiceDialog
          open={invoiceDialog.open}
          data={invoiceDialog.invoice}
          manifestId={manifestId}
          showNotification={showNotification}
          onClose={() => setInvoiceDialog({ open: false, invoice: null })}
          title={translatedTextsObject.invoice}
        />
      </InvoiceLookupsProvider>
    </>
  )
}

export default InvoiceAttachTable
